import {useTour} from "@reactour/tour"
import {Popover, Tooltip} from "antd"
import {useContext, useEffect, useRef, useState} from "react"
import {createPortal} from "react-dom"
import {ConfigContext} from "src/app"
import onboardingService from "src/services/onboarding"
import tenantService from "src/services/tenant.service"
import Icon from "../shared/components/material-icon"

export default function HeaderContent({children}: {children: JSX.Element | JSX.Element[]}) {
  const {headerWrapper, tenant, setCurrentTour, currentTour} = useContext(ConfigContext)
  const {setIsOpen, currentStep} = useTour()

  if (!headerWrapper) return <></>
  if (!headerWrapper.current) return <></>

  if (tenant && tenant.demo) {
    const [maxWidth, setMaxWidth] = useState<number>(headerWrapper.current?.clientWidth ?? 0)
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
      if (!ref.current) return
      const handleResize = () => setMaxWidth((headerWrapper.current?.clientWidth ?? 0) - ref.current.clientWidth)
      handleResize()
      window.addEventListener("resize", handleResize)
      return () => window.removeEventListener("resize", handleResize)
    }, [ref])

    return createPortal(
      <div className="flex flex-row items-stretch">
        <div className="flex-1 self-end" style={{maxWidth}}>{children}</div>
        <div ref={ref} className="flex justify-center items-center p-3 border-b border-gray-200">
          <Tooltip
            title={
              <span className="font-normal text-sm text-center text-white">
                В данный момент у вас отображены шаблонные данные. Удалите демо-данные, если вы готовы приступить к
                работе со своими данными.
              </span>
            }
            trigger="hover"
            overlayStyle={{backdropFilter: "blur(8px)"}}
            placement="bottomLeft"
          >
            <div
              id="step18"
              className="flex items-center gap-3 p-3 my-auto rounded-lg cursor-pointer bg-gray-70 text-nowrap"
              onClick={() => {
                onboardingService
                  .patch({
                    block_name: currentTour,
                    step_name: currentStep,
                    passed: true
                  })
                  .then(() => {
                    setIsOpen(false)
                    setCurrentTour("")
                  })

                tenantService.moveToProduction()
              }}>
              <span className="text-black text-sm font-medium">Удалить демо-данные</span>
              <Icon icon="info" className="text-primary text-1xl" />
            </div>
          </Tooltip>
        </div>
      </div>,
      headerWrapper.current
    )
  }

  return createPortal(children, headerWrapper.current)
}
